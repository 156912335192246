import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';

const Rsvp = () => {
    const [guests, setGuests] = useState([]);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    axios.defaults.withCredentials = true;

    useEffect(() => {
        const fetchGuests = async () => {
            try {
                const response = await axios.get(`${apiUrl}/getRsvpStatus`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setGuests(response.data);
            } catch (error) {
                setError('Failed to fetch guests');
            }
        };

        fetchGuests();
    }, [apiUrl]);

    const handleRsvpChange = (index, rsvp) => {
        setGuests(prevGuests => {
            const updatedGuests = [...prevGuests];
            updatedGuests[index].rsvp = rsvp;
            return updatedGuests;
        });
    };

    const handleFormSubmit = async () => {
        try {
            await axios.post(`${apiUrl}/updateRSVPStatus`, { guests }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            alert('RSVP status updated successfully');
        } catch (error) {
            setError('Failed to update RSVP status');
        } finally {
            setShowModal(false);
            setFormSubmitting(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Check if any guest has selected "true" for RSVP
        const isAttending = guests.some(guest => guest.rsvp === true);

        if (isAttending) {
            setShowModal(true);
        } else {
            handleFormSubmit();
        }
    };

    return (
        <div className="container">
            <div className="d-flex">
                <div className="col-sm-6">
                <a href="/home" className="a-no-underline"><h1 className='monoton-regular'>STUDIO 30</h1></a>
                <br></br>
                    <h3>RSVP</h3>
                    <p>PLEASE UPDATE YOUR RSVP STATUS BY <strong>AUGUST 30TH</strong>.</p>
                    {error && <p className="error">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>RSVP</th>
                                </tr>
                            </thead>
                            <tbody>
                                {guests.map((guest, index) => (
                                    <tr key={guest.id}>
                                        <td>{guest.displayname}</td>
                                        <td>
                                            <select className="form-select" value={guest.rsvp} onChange={(e) => handleRsvpChange(index, e.target.value === 'true')}>
                                                <option value="">Select Option</option>
                                                <option value="true">Attending</option>
                                                <option value="false">Not Attending</option>
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="submit" className="btn btn-secondary" disabled={formSubmitting}>
                            Update
                        </button>
                    </form>
                </div>
            </div>

            {/* Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Attendance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>By confirming, you agree that only guests explicitly listed on the RSVP will attend, and that you will adhere to the <a href="/MoodBoard" target="_blank">dress code</a>. Are you sure you want to submit your RSVP?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleFormSubmit}>
                        Agree
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Rsvp;
