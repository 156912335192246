import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function ContactInfo() {
    
    const[values, setValues] = useState({ email: '', phone: '' });
    const[error, setError] = useState('');
    const[displayName, setDisplayName] = useState('');
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    axios.defaults.withCredentials = true;

    useEffect(() => {
        const fetchDisplayName = async () => {
            try {
                const response = await axios.get(`${apiUrl}/getUserName`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setDisplayName(response.data);

            } catch (error) {
                console.error('Error fetching user information: ', error);
                setError('Failed to fetch user information. Please try again.');
            }
        };

        fetchDisplayName();
    }, [apiUrl]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const phoneNumberRegex = /^\d{10}$/;
        if (!phoneNumberRegex.test(values.phone)) {
            setError('Please enter a valid 10-digit phone number');
            return;
        }
        
        try {
            // Get token and post email, phone
            const token = localStorage.getItem('token');
            if(!token) {
                setError('Authentication error. Please log in again.');
                return;
            }

            const response = await axios.post(`${apiUrl}/setContactInfo`, 
                { email: values.email, phone: values.phone, },
                { headers: { Authorization: `Bearer ${token}` }, });

            if (response.status === 200) {
                navigate('/Home');
            } else { 
                setError('Failed to update contact information. Please try again.');
            }
        } catch (error) {
            console.error('Error updating contact information: ', error);
            setError('Oops, invalid access code. Please try again.');
        }
    };

    return (

        <div className="container">
            <div className="d-flex justify-content-center align-items-center">
                <div className="col-sm-6">
                    <h1 className="text-center monoton-regular">STUDIO 30</h1>
                    <p>YOU MADE IT ON THE LIST {displayName.toUpperCase()}! BEFORE YOU ARE ALLOWED IN, SUBMIT THE BELOW INFORMATION.</p>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="EXAMPLE@EMAIL.COM" onChange={e => setValues({...values, email: e.target.value})}/>
                        </div>
                        <div className="form-group">
                            <input type="number" className="form-control" placeholder="5626959654" onChange={e => setValues({...values, phone: e.target.value})}/>
                        </div>
                        <div className="form-group text-center">
                            <button type="submit" className="btn btn-link text-white border-0 btn-no-underline">
                                SUBMIT
                            </button>
                        </div>
                    </form>
                    {error && <p className="text-danger text-center">{error}</p>}
                </div>
            </div>
            <div class="disco-bottom-image-container">
                <img src="/mirrorball.png" alt="Mirror Ball" class="disco-bottom-image"></img>
            </div>
        </div>

    )
}
