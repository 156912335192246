import React from 'react'

function MoodBoard() {
    return (
        <div className="container">
            <a href="/home" className="a-no-underline"><h1 className='monoton-regular'>STUDIO 30</h1></a>
            <br></br>
            <h3>DRESS CODE</h3>
            <p>THE DRESS CODE FOR THE EVENING IS STUDIO 54—THINK DISCO CHIC. THE DRESS CODE IS MANDATORY, SO COME DRESSED ACCORDINGLY OR BE PREPARED TO BE TURNED AWAY AT THE DOOR. YOU'VE BEEN WARNED! BELOW IS A MOOD BOARD FOR INSPIRATION.</p>
            <div className="d-flex">
                <div class="iframe-container">
                    <iframe src="https://petracoding.github.io/pinterest/board.html?link=abeljrenteria/studio-30/&amp;hideHeader=1&amp;transparent=1"></iframe>
                </div>
            </div>
        </div>

    )
}

export default MoodBoard