import React from 'react';
import { useNavigate } from 'react-router-dom';

const SignOutButton = () => {
    const navigate = useNavigate();

    const handleSignOut = () => {
        localStorage.removeItem('token');
        navigate('/Login');
    };

    return (
        <button onClick={handleSignOut} className="btn btn-link text-white border-0 btn-no-underline">Sign Out</button>
    );
};

export default SignOutButton;