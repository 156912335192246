import React, { useState } from 'react'
import Login from './Login'
import Home from './Home'
import ContactInfo from './ContactInfo'
import EventDetails from './EventDetails'
import Rsvp from './Rsvp'
import MoodBoard from './MoodBoard'
import Gallery from './Gallery'
import Faq from './Faq'
import ProtectedRoute from './ProtectedRoute'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

function App() {

  // State to trigger re-render on login
  const [isAuthenticated, setIsAUthenticated] = useState(!!localStorage.getItem('token'));
  //const isAuthenticated = !!localStorage.getItem('token')

  // Set authentication state
  const handleAuthChange = () => {
    setIsAUthenticated(!!localStorage.getItem('token'));
  };

  return (
    <Router>
      <Routes>
        <Route path='/Login' element={<Login onAuthChange={handleAuthChange} />}></Route>
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated}></ProtectedRoute>}>
          <Route path='/' element={<Home onAuthChange={handleAuthChange} />}></Route>
          <Route path='/Home' element={<Home />}></Route>
          <Route path='/ContactInfo' element={<ContactInfo />}></Route>
          <Route path='/EventDetails' element={<EventDetails />}></Route>
          <Route path='/Rsvp' element={<Rsvp />}></Route>
          <Route path='/MoodBoard' element={<MoodBoard />}></Route>
          <Route path='/Gallery' element={<Gallery />}></Route>
          <Route path='/Faq' element={<Faq />}></Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App