import React from 'react'

function Gallery() {
    return (
        <div className="container">
            <div className="d-flex justify-content-center align-items-center">
                <p>Coming soon. Check back after the party to view the photos.</p>
            </div>
        </div>

    )
}

export default Gallery