import React from 'react'

function EventDetails() {
    return (
        <div className="container">
            {/* <div className="disco-top-image-container">
                <img src="/discoball.png" alt="disco ball" className="disco-top-image" />
            </div>  */}
            <div className="">
                <div className="">
                    <a href="/home" className="a-no-underline"><h1 className='monoton-regular'>STUDIO 30</h1></a>
                    <br></br>
                    <h3>EVENT DETAILS</h3>
                    <br></br>
                    <p><strong>WHEN:</strong></p>
                    <p>SATURDAY, SEPTEMBER 21, 2024. 6:00 PM TO LATE.</p>
                    <br></br>
                    <p><strong>WHERE:</strong></p>
                    <p>SKY LOUNGE AT EIGHTH & GRAND. 770 S GRAND AVE, LOS ANGELES, CA 90017.</p>
                    <br></br>
                    <p><strong>WEAR:</strong></p>
                    <p>STUDIO 54 ATTIRE REQUIRED. <a href="/MoodBoard" target="_blank">DRESS CODE DETAILS</a>.</p>
                    <br></br>
                </div>
            </div>
        </div>
    )
}

export default EventDetails