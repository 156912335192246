import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Login({ onAuthChange }) {

    const [values, setValues] = useState({ code: '' });
    const [error, setError] = useState('');
    const navigate = useNavigate()
    const apiUrl = process.env.REACT_APP_API_URL;
    const inputRef = useRef(null);
    const cursorRef = useRef(0);

    axios.defaults.withCredentials = true;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Validate login code and generate token
            const response = await axios.post(`${apiUrl}/login`, { code: values.code });
            const { token } = response.data;
            localStorage.setItem('token', token);

            onAuthChange();

            // Redirect based on user contact existence 
            const userResponse = await axios.get(`${apiUrl}/getContactInfo`, { headers: { Authorization: `Bearer ${token}` } });
            //console.log(userResponse);
            if (userResponse.data[0].email) {
                navigate('/Home');
            } else {
                navigate('/ContactInfo');
            }
        } catch (error) {
            setError('Oops, invalid access code. Please try again.');
        }
    };

    const handleChange = (e) => {
        const input = inputRef.current;
        let value = e.target.value.toUpperCase();
        cursorRef.current = input.selectionStart;

        value = value.replace(/-/g, '');
        value = value.replace(/[^a-zA-Z0-9]/g, '');

        let newCursorPos = cursorRef.current;
        // Add hyphens
        if (value.length >= 2) {
            value = value.slice(0, 2) + '-' + value.slice(2);
            if (cursorRef.current > 2) newCursorPos++;
        }
        if (value.length >= 7) {
            value = value.slice(0, 7) + '-' + value.slice(7);
            if (cursorRef.current > 7) newCursorPos++;
        }
        if (value.length > 10) value = value.slice(0, 10);
        setValues({ code: value });

        setTimeout(() => {
            input.setSelectionRange(newCursorPos, newCursorPos);  // Reset the cursor position after update
        }, 1);
    };

    return (
        <div className="container login-page-container">
            <div className="login-container">
                <h1 className="text-center monoton-regular">STUDIO 30</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input ref={inputRef} type="text" className="form-control" placeholder="ACCESS CODE" value={values.code} onChange={handleChange} />
                    </div>
                    {error && <p className="text-danger">{error}</p>}
                    <div className="form-group text-center">
                        <button type="submit" className="btn btn-link text-white border-0 btn-no-underline">
                            Enter
                        </button>
                    </div>
                </form>
            </div>
            <div class="disco-bottom-image-container">
                <img src="/mirrorball.png" alt="Mirror Ball" class="disco-bottom-image"></img>
            </div>
        </div>
    )
}