import React from 'react'

function Faq() {
    return (
        <div className="container">
            <a href="/home" className="a-no-underline"><h1 className='monoton-regular'>STUDIO 30</h1></a>
            <br></br>
            <h3 class="faq-title">FAQ</h3>
            <br></br>
            <div class="faq-item">
                <h2 class="faq-question">WHAT IS STUDIO 30?</h2>
                <p class="faq-answer">STUDIO 30 IS AN EXCLUSIVE EVENT HOSTED TO CELEBRATE ABEL'S...3OTH...BIRTHDAY. IT FEATURES A THEMED PARTY WITH DANCING, FOOD, DRINKS, AND FUN ACTIVITIES.</p>
            </div>

            <div class="faq-item">
                <h2 class="faq-question">WILL THERE BE FOOD?</h2>
                <p class="faq-answer">YES, BUT MOST IMPORTANTLY, AN OPEN BAR. BE SURE TO RSVP SO THERE IS AN ACCURATE COUNT AND SEATING ARRANGEMENT.</p>
            </div>

            <div class="faq-item">
                <h2 class="faq-question">WHAT IS THE DRESS CODE?</h2>
                <p class="faq-answer">THE DRESS CODE FOR THE EVENT IS STUDIO 54 ATTIRE. VISIT THE DRESS CODE PAGE FOR INSPIRATION. THE DRESS CODE IS REQUIRED.</p>
            </div>

            <div class="faq-item">
                <h2 class="faq-question">IS THE DRESS CODE REALLY REQUIRED?</h2>
                <p class="faq-answer">YES. DON'T TEST ME.</p>
            </div>

            <div class="faq-item">
                <h2 class="faq-question">HOW DO I RSVP?</h2>
                <p class="faq-answer">TO RSVP, VISIT THE RSVP PAGE AND UPDATE YOUR STATUS. MAKE SURE TO DO THIS BY AUGUST 30TH.</p>
            </div>

            <div class="faq-item">
                <h2 class="faq-question">CAN I BRING GUESTS?</h2>
                <p class="faq-answer">NO.</p>
            </div>

            <div class="faq-item">
                <h2 class="faq-question">CAN I BRING MY KID?</h2>
                <p class="faq-answer">UNFORTUNATELY THERE ARE NO KIDS ALLOWED. ONLY NAMES EXPLICITLY LISTED ON THE RSVP ARE INVITED.</p>
            </div>

            <div class="faq-item">
                <h2 class="faq-question">WHERE DO I PARK?</h2>
                <p class="faq-answer">THERE IS A PARKING STRUCTURE RIGHT ACROSS FROM THE BUILDING, LAZ PARKING AT 725 S GRAND AVE, LOS ANGELES, CA 90017. THIS PARTICULAR PARKING STRUCTURE IS OPEN 24 HOURS AND THE PRICE IS $7. OTHERWISE, YOU CAN TRY YOUR LUCK WITH STREET PARKING OR YOU CAN TAKE A RIDE SHARE TO THE BUILDING.</p>
            </div>

            <div class="faq-item">
                <h2 class="faq-question">WHERE DO I GO?</h2>
                <p class="faq-answer">THE PARTY IS IN THE SKY LOUNGE AT THE EIGHTH & GRAND APARTMENTS. WHEN YOU ARRIVE, THE DOORMAN WILL LET YOU INTO THE BUILDING LOBBY AND YOU WILL LET THEM KNOW YOU ARE GOING TO THE SKY LOUNGE FOR AN EVENT. THE DOORMAN WILL THEN LET YOU INTO THE ELEVATORS WHERE YOU WILL GO TO THE 7TH FLOOR AND FOLLOW THE SIGNS TO THE SKY LOUNGE.</p>
            </div>

            <div class="faq-item">
                <h2 class="faq-question">ARE THERE ANY HOTELS IN THE AREA IF I DONT WANT TO DRIVE?</h2>
                <p class="faq-answer">YES. THERE IS THE LOS ANGELES ATHLETIC CLUB WHERE I CAN GET YOU MY GUEST OF A MEMBER RATE. OTHERWISE, THERE IS HOTEL PER LA, SHERATON GRAND, AND THE FREEHAND ALL WITHIN WALKING DISTANCE.</p>
            </div>

            <div class="faq-item">
                <h2 class="faq-question">WHAT SHOULD I DO IF I HAVE MORE QUESTIONS?</h2>
                <p class="faq-answer">DONT. JK, JUST SEND ME A MESSAGE.</p>
            </div>
        </div>

    )
}

export default Faq