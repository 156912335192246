import React, { useState, useEffect } from 'react'
import axios from 'axios'
import SignOutButton from './Components/SignOutButton'

export default function Home() {

    const[error, setError] = useState('');
    const[displayName, setDisplayName] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    axios.defaults.withCredentials = true;

    useEffect(() => {
        const fetchDisplayName = async () => {
            try {
                const response = await axios.get(`${apiUrl}/getUserName`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setDisplayName(response.data);

            } catch (error) {
                console.error('Error fetching user information: ', error);
                setError('Failed to fetch user information. Please try again.');
            }
        };

        fetchDisplayName();
    }, [apiUrl]);

    return (

        <div className="container home-page-container">
            <div className="home-container">
                    <h1 className='monoton-regular'>STUDIO 30</h1>
                    <p>YOU MADE IT ON THE LIST {displayName.toUpperCase()}. YOUR PRESENCE HAS BEEN REQUESTED TO JOIN IN ABEL’S 3OTH BIRTHDAY CELEBRATION. </p>
                    <br></br>
                    <div className="text-center">
                        <a href="/EventDetails" className="text-white link-item">EVENT DETAILS</a><br></br>
                        <a href="/MoodBoard" className="text-white link-item">DRESS CODE</a><br></br>
                        <a href="/Rsvp" className="text-white link-item">RSVP</a><br></br>
                        <a href="/Gallery" className="text-white link-item">GALLERY</a><br></br>
                        <a href="/Faq" className="text-white link-item">FAQ</a><br></br>
                        <SignOutButton />
                    </div>
            </div>
            <br></br>
            <div class="disco-bottom-image-container">
                <img src="/mirrorball.png" alt="Mirror Ball" class="disco-bottom-image"></img>
            </div>
        </div>
        
    )

}


